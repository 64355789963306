import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/nuestro-adn",
    name: "OurDNA",
    component: () =>
      import(/* webpackChunkName: "founders" */ "../views/about/OurDna.vue"),
  },
  {
    path: "/junta-directiva",
    name: "Council",
    component: () =>
      import(/* webpackChunkName: "council" */ "../views/about/Council.vue"),
  },
  {
    path: "/nuestros-fundadores",
    name: "Founders",
    component: () =>
      import(/* webpackChunkName: "founders" */ "../views/about/Founders.vue"),
  },
  {
    path: "/adn-misionero",
    name: "MissionsDNA",
    component: () =>
      import(
        /* webpackChunkName: "missions-dna" */ "../views/missions/Dna.vue"
      ),
  },
  {
    path: "/empresas-misioneras",
    name: "MissionsBusiness",
    component: () =>
      import(
        /* webpackChunkName: "missions-business" */ "../views/missions/Business.vue"
      ),
  },
  {
    path: "/proyectos-misioneros",
    name: "MissionsProjects",
    component: () =>
      import(
        /* webpackChunkName: "missions-projects" */ "../views/missions/Projects.vue"
      ),
  },
  {
    path: "/trabajo-social",
    name: "SocialWork",
    component: () =>
      import(
        /* webpackChunkName: "social-work" */ "../views/missions/SocialWork.vue"
      ),
  },
  {
    path: "/series",
    name: "PastorSeries",
    component: () =>
      import(/* webpackChunkName: "social-work" */ "../views/Series.vue"),
  },
  {
    path: "/sedes",
    name: "FindUs",
    component: () =>
      import(/* webpackChunkName: "find-us" */ "../views/contact/FindUs.vue"),
  },
  {
    path: "/contactanos",
    name: "ContactUs",
    component: () =>
      import(
        /* webpackChunkName: "contact-us" */ "../views/contact/ContactUs.vue"
      ),
  },
  {
    path: "/material-institucional",
    name: "2022Badge",
    component: () =>
      import(
        /* webpackChunkName: "contact-us" */ "../views/resources/Badge.vue"
      ),
  },
  {
    path: "/material-pfi",
    name: "PfiResources",
    component: () =>
      import(/* webpackChunkName: "contact-us" */ "../views/resources/PFI.vue"),
  },
  {
    path: "/nueva-donacion",
    name: "Donations",
    component: () =>
      import(
        /* webpackChunkName: "donations" */ "../views/donation/Donation.vue"
      ),
  },
  {
    path: "/respuesta-donacion",
    name: "DonationsResponse",
    component: () =>
      import(
        /* webpackChunkName: "donations" */ "../views/donation/Response.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const keys = Object.keys(to.query).length;
      if (keys === 43) {
        next();
      } else {
        next({ path: "/", replace: true });
      }
    },
  },
  {
    path: "/politica-privacidad",
    name: "Privacy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-terms" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/terminos-condiciones",
    name: "TermsCond",
    component: () =>
      import(
        /* webpackChunkName: "privacy-terms" */ "../views/TermsConditions.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;
