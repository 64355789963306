import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Spin, Skeleton, Modal, Popconfirm } from "ant-design-vue";
import { createMetaManager } from "vue-meta";
import "ant-design-vue/dist/antd.css";

createApp(App)
  .use(store)
  .use(router)
  .use(Spin)
  .use(Modal)
  .use(Skeleton)
  .use(Popconfirm)
  .use(createMetaManager())
  .mount("#app");
