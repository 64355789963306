
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SiteFooter",
  computed: {
    showLogo(): boolean {
      return this.deviceType === ScreenType.largeDesktop;
    },
    showProphetic(): boolean {
      const device = this.deviceType;
      return device === ScreenType.mobile || device === ScreenType.largeDesktop;
    },
    deviceType(): ScreenType {
      return ResponsiveManager.retrieveScreenSize();
    },
  },
});
