import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3544f7d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "dropdown-content" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.subitems.length)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        (!_ctx.externalTarget)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: _ctx.target }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.target,
              target: "_blank",
              rel: "noopener noreferrer"
            }, _toDisplayString(_ctx.title), 9, _hoisted_2))
      ]))
    : (_openBlock(), _createElementBlock("li", _hoisted_3, [
        _createElementVNode("div", {
          class: "nav-dropdown",
          onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleHover(true))),
          onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleHover(false)))
        }, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTap && _ctx.handleTap(...args))),
            class: "dropdown-title"
          }, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown-glass", { open: _ctx.isOpen }])
          }, [
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subitems, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  (!item.externalTarget)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: { name: item.target }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: item.target,
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }, _toDisplayString(item.title), 9, _hoisted_5))
                ]))
              }), 128))
            ])
          ], 2)
        ], 32)
      ]))
}