import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigatorMobile = _resolveComponent("NavigatorMobile")!
  const _component_NavigatorDesktop = _resolveComponent("NavigatorDesktop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      class: _normalizeClass(["global-nav", { opened: _ctx.isMenuOpen }])
    }, [
      (_ctx.showMobile)
        ? (_openBlock(), _createBlock(_component_NavigatorMobile, {
            key: 0,
            modelValue: _ctx.isMenuOpen,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMenuOpen) = $event)),
            state: _ctx.state
          }, null, 8, ["modelValue", "state"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NavigatorDesktop, { state: _ctx.state }, null, 8, ["state"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["global-nav glass-background", { opened: _ctx.isMenuOpen }])
    }, null, 2)
  ], 64))
}